<template>
    <div v-if="isReady">
        <h6 v-if="!provider?.length" class="text-center">{{ $t('Choose your verification method') }}</h6>
        <!-- <h6 v-else class="text-center">Choose your verification method</h6> -->
        <div class="mb-2 mt-2" v-if="provider?.length > 0">
            <label class="form-label" for="username">{{ $t(serviceInputNames[provider]) }}</label>
            <input type="text" name="username" v-model="trimmedUsername" id="username" class="form-control"  v-on:keyup.enter="sendOTP" :class="{ 'is-invalid' : errors.username }" autocomplete="username" required :disabled="state.isAuthenticated" />
            <!-- <label for="username">{{ showParticipate ? 'Mobile No' : (showSignup ? 'Email or Mobile No' : 'Email, Username or Mobile No') }}</label> -->
            <span class="invalid-feedback mt-0" v-if="errors.username">{{ errors.username }}</span>
        </div>

        <ErrorComponent v-if="Object.keys(errors).length" :errors="errors" :capsLockOn="capsLockOn" :key="state.updated" :exclude="['username']"/> 
        
        <div class="form-group mb-2" v-if="provider.length == 0 || provider == 'fido2'">
            <div class="col" v-for="service in services">
                <button type="button" v-if="!state.isAuthenticated && (service !== 'otp' && service !== 'app' && service !== 'fido2' && (showReset || showSignup)) || state.isAuthenticated" class="btn btn-o365-login mt-1" @click="selectProvider(service)">{{ serviceNames[service] ?? service.toUpperCase() }}</button>
            </div>
        </div>
        
        <div class="form-group mb-2" v-else>
            <div class="col">
                <button type="button" class="btn btn-o365-login mt-1" @click="sendOTP()">{{ $t('Send one-time password') }}</button>
            </div>            
        </div>

        <div class="d-flex justify-content-between align-items-center my-2" v-if="showReset || showSignup">
            <a type="button" @click="resetState">Back</a>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, onMounted, inject, onBeforeMount } from 'vue';
    import { createRequest, validateEmail, validatePhone, hasQueryParam, loadReCaptcha, serviceNames, serviceInputNames } from 'o365.modules.Login.shared.js';
    import ErrorComponent from 'o365.vue.components.Login.Errors.vue';
    
    const updateState = inject('updateState') as Function;
    const resetState = inject('resetState') as Function;
    const isBusy = inject('isBusy') as Function;
    const setErrors = inject('setErrors') as Function;
    const authenticated = inject('authenticated') as Function;
    const props = defineProps({
        state: { type: Object, required: true },
        errors: { type: Object, required: false, default: {} }, 
        capsLockOn: { type: Boolean, required: false, default: false }
    });
    
    const isRecaptchaConfigured = computed(() => props.state.siteKey && props.state.siteKey.length > 0);

    const services = ref<string[]>([]);
    const username = ref<string>('');
    const provider = ref<string>('');
    const trimmedUsername = computed({
        get() {
            return username.value;
        },
        set(value) {
            username.value = value?.replace(/\s/g, '');
            if(props.errors.value?.username){
                delete props.errors.value.username;
            }
        }
    });
    const isReady = ref(false);
    const showSignup = computed(() => props.state.action === 'signup');
    const showReset = computed(() => props.state.action === 'reset');
    const showParticipate = computed(() => hasQueryParam('smsToken') && props.state.authentication?.smsToken?.enabled == true );

    async function getServices(){
        try {
            const response = await createRequest('/api/login/services', null);
            if(response.ok){
                services.value = await response.json();
                if(services.value.length == 1){
                    provider.value = services.value[0];
                }
            }
        } catch(e){
            props.state.multiFactorState = 0;
        }
        finally{
            isBusy(false);
        }
    }

    function validate(input, provider){
        if(provider === 'email') {
            if(!validateEmail(input)) {
                setErrors({username: 'Invalid Email'});
                return false;
            };
        } else if(provider === 'sms') {
            if(!validatePhone(input)) {
                setErrors({username: 'Invalid Mobile No'});
                return false;
            };
        }
        return true;
    }

    function selectProvider(selected){
        provider.value = selected;
        if(props.state.isAuthenticated || provider.value === 'otp'){
            providerSelected(provider.value);
        } 
    }

    async function sendOTP(){
        // if(validateEmail(username.value)){
            // //provider.value = 'email';
        // } else if(validatePhone(username.value)){
            // //provider.value = 'sms';
        // } else if(props.state.isAuthenticated && props.state.scheme != 'SqlIdentity'){
            // //provider.value = 'sms';
        // }
        await providerSelected(provider.value);
    }

    async function providerSelected(selectedProvider) {
        try {
            setErrors({});
            var recapthaToken;
            if(!props.state.isAuthenticated && (!username.value || username.value.length == 0)){
                setErrors({ username: 'Username field is required'});
                return;
            }
            isBusy(true);
            if(showSignup.value || showReset.value || showParticipate.value){
                if(!validate(username.value, selectedProvider)) return;
                if(isRecaptchaConfigured.value){
                    recapthaToken = await grecaptcha.execute(props.state.siteKey, {action: 'submit'});
                }
            }
            provider.value = selectedProvider;
            var data = {
                username: username.value,
                reToken: recapthaToken,
                provider: provider.value,
                action: props.state.action
            }
            await authenticateMfa(data);
        } finally {
            isBusy(false);
        }
    }

    async function authenticateMfa(data){
        if(!props.state.isAuthenticated && username.value.length <= 0){
            setErrors({ username: 'Username field is required' });
            return;
        }
        var route = '/api/login/mfa';
        const response = await createRequest(route, data);
        if(response.ok){
            var json = await response.json();
            if(!props.state.isAuthenticated){
                props.state.username = username.value;
            }
            // props.state.multiFactorState = json.multiFactorState;
            // props.state.currentProvider = provider.value;
            updateState(json);
        } else if(response.status == 400){
            var json = await response.json(); 
            if(json.modelState?.Username?.errors?.length > 0)            
                setErrors({ username: json.modelState.Username.errors[0].errorMessage});
            else if(json.modelState?.Token?.errors?.length > 0)
                setErrors({ error: json.modelState.Token.errors[0].errorMessage});
            else if(json.modelState?.error?.errors?.length > 0)
                setErrors({ error: json.modelState.error.errors[0].errorMessage });
            
            if(json.multiFactorState != null)
            {
                props.state.multiFactorState = json.multiFactorState;
            }
        } else {
            const text = await response.text();
            setErrors(text);
        }
    }

    onMounted(async () => {        
        console.log("MFA select mounted");       
        if(props.state.isAuthenticated){
            trimmedUsername.value = props.state.username;
        }
        if(props.state.isTwoFactorVerified){
            authenticated();
        }
        provider.value = props.state.currentProvider;
        console.log('Current provider:', provider.value);
        console.log('Current provider Title:', serviceInputNames[provider.value]);
        isReady.value = true;
    });

    onBeforeMount(async () => {
        loadReCaptcha(props.state.siteKey);
        await getServices();
    });

</script>